export default class Toggler{
    static hide(element, duration = 220){
        return new Promise(resolve => {
            if(!element || element.dataset.toggleShow == 'false' || element.dataset.toggleProcess){
                resolve(this);
            }
            element.dataset.toggleProcess = true;
            element.style['height'] = `${element.offsetHeight}px`;
            element.offsetHeight;
            element.style['overflow'] = 'hidden';
            element.style['transition'] = `height ${duration}ms ease, margin ${duration}ms ease, padding ${duration}ms ease`;
            element.style['height'] = 0;
            element.style['marginTop'] = 0;
            element.style['marginBottom'] = 0;
            element.style['paddingTop'] = 0;
            element.style['paddingBottom'] = 0;
            setTimeout(() => {
                element.dataset.toggleShow = false;
                delete element.dataset.toggleProcess;
                element.style['transition'] = '';
                element.style['overflow'] = '';
                element.style['height'] = '';
                element.style['marginTop'] = '';
                element.style['marginBottom'] = '';
                element.style['paddingTop'] = '';
                element.style['paddingBottom'] = '';
                element.style['display'] = 'none';
                if(typeof AOS !== 'undefined'){
                    AOS.refresh()
                }
                resolve(element);
            }, duration);
        });
    }
    static show(element, duration = 220){
        return new Promise(resolve => {
            if(!element || element.dataset.toggleShow !== 'false' || element.dataset.toggleProcess){
                return;
            }
            element.dataset.toggleProcess = true;
            element.style['display'] = '';
            const styles = window.getComputedStyle(element);
            const margin = {
                top: parseFloat(styles['marginTop']),
                bottom: parseFloat(styles['marginBottom'])
            };
            const padding = {
                top: parseFloat(styles['paddingTop']),
                bottom: parseFloat(styles['paddingBottom'])
            };
            const height = element.offsetHeight;  
    
            element.style['height'] = 0;      
            element.style['marginTop'] = 0;
            element.style['marginBottom'] = 0;
            element.style['paddingTop'] = 0;
            element.style['paddingBottom'] = 0;
    
            element.offsetHeight;
            element.style['transition'] = `height ${duration}ms ease, margin ${duration}ms ease, padding ${duration}ms ease`;
            element.style['overflow'] = 'hidden';
            element.style['height'] = `${height}px`;   
            element.style['marginTop'] = `${margin.top}px`;
            element.style['marginBottom'] = `${margin.bottom}px`;
            element.style['paddingTop'] = `${padding.top}px`;
            element.style['paddingBottom'] = `${padding.bottom}px`;

            setTimeout(() => {
                delete element.dataset.toggleShow;
                delete element.dataset.toggleProcess;
                element.style['transition'] = '';
                element.style['height'] = ``; 
                element.style['marginTop'] = '';
                element.style['marginBottom'] = '';
                element.style['paddingTop'] = '';
                element.style['paddingBottom'] = '';
                element.style['overflow'] = '';
                if(typeof AOS !== 'undefined'){
                    AOS.refresh()
                }
                resolve(element);
            }, duration);
        });
    }    
    static toggle(element, duration = 220) {
        if(!element ||  element.dataset.toggleProcess){
            return;
        }
        if(element.dataset.toggleShow === 'false'){
            return Toggler.show(element, duration);
        }else{
            return Toggler.hide(element, duration);
        }
    }
}