import Toggler from './plugins/toggler'; 
import Swiper from './plugins/swiper';
import Plyr from './plugins/plyr';
import './global/global';
import './plugins/plyr.polyfilled';
import Notify from './global/notify';

document.addEventListener('DOMContentLoaded', initMainPage);

function initMainPage(){
    initOffer();
    initTeam();
    initFaq();
    initTagline();
    initContacts();
}

function initOffer(){
    const player = new Plyr('.offer__video', {
        controls: [],
        muted: true,
        clickToPlay: false,
        loop: {
            active: true
        },
        fullscreen: {
            enabled: false
        }
    });
    setTimeout(() => player.play(), 200);

    new Swiper('.offer__slider', {
        autoplay: {
          delay: 2000,
        },
        speed: 2000,
        loop: true,
        slidesPerView: 1,
        allowTouchMove: false,
        disableOnInteraction: true
    });
}

function initTeam(){
    const navigations = document.querySelector('.team__navigation');
    const list = document.querySelector('.team__list');
    const count = [...list.querySelectorAll('.team__item')].length;
    new Swiper(list, {
        loop: count >= 4,
        spaceBetween: 32,
        navigation: {
            nextEl: '.team__navigation-next',
            prevEl: '.team__navigation-prev',
        },
        breakpoints: {
            320: {
                slidesPerView: 1,
                spaceBetween: 24,
                loop: true
            },
            420: {
                slidesPerView: 2,
                spaceBetween: 32,
                loop: count >= 3
            },
            840: {
                slidesPerView: 3,
            }
        },
        on:{
            beforeResize: swiper => {
                if(window.innerWidth > 840 && count < 4){
                    navigations.classList.add('hide');
                    return;
                }
                
                if(window.innerWidth <= 840 && count < 3){
                    navigations.classList.add('hide');
                    return;
                }

                navigations.classList.remove('hide');
            },
            slideChange: swiper => {
                const currentSlide  = swiper.slides[swiper.realIndex];                
                navigations.querySelector('span').innerText = currentSlide.dataset.index;
            },
            init: swiper => {
                const currentSlide  = swiper.slides[swiper.realIndex];                
                navigations.querySelector('span').innerText = currentSlide.dataset.index;
            }
        }
      });
}
function initFaq(){
    document.querySelectorAll('.faq__list').forEach(x => {
        x.querySelectorAll('.faq__item').forEach((item, index) => {
            const body = item.querySelector('.faq__item-answer');
            if(index > 0){
                Toggler.toggle(body, 0);
            }else{
                item.classList.add('faq__item_open')
            }
        })
        x.addEventListener('click', e => {
            const head = e.target.closest('.faq__item-head');
            if(!head){
                return;
            }
            const item = head.closest('.faq__item');
            const body = item.querySelector('.faq__item-answer');
            Toggler.toggle(body)
                .then(x => item.classList.toggle('faq__item_open'));
        })
    })
}

function initTagline(){
    new Swiper('.tagline__slider', {
        spaceBetween: 50,
        centeredSlides: true,
        speed: 10000,
        autoplay: {
          delay: 1,
        },
        loop: true,
        slidesPerView:'auto',
        allowTouchMove: false,
        disableOnInteraction: true
    });
}

function initContacts(){
    const form = document.querySelector('.contacts .contacts__form');
    if(!form){
        return;
    }
    form.addEventListener('submit', e => {
        e.preventDefault();
        const formData = new FormData(form);
        showLoader();
        fetch(form.action, {
            method: 'POST',
            body: formData
        }).then(x => x.json())
        .then(response => {
            if(response.isError){
                Notify.error(response.message);
                return;
            }
            Notify.success(response.message);
            
            form.querySelectorAll('input').forEach(x => x.value = '');
            const file = form.querySelector('input[type="file"]');
            if(file){
                file.type = 'text';
                file.type = 'file';
                const valueFileText = form.querySelector('.file__value');
                valueFileText.innerText = valueFileText.dataset.default;
                file.closest('.file').classList.remove('file_exist');
            }
        })
        .finally(x => {
            hideLoader();
        })
    })
}

function showLoader(){
    const loader = document.querySelector('.loader');
    if(!loader){
        return;
    }
    loader.classList.add('loader_show');
    document.querySelector('html').classList.add('scroll-disabled');
}
function hideLoader(){
    const loader = document.querySelector('.loader');
    if(!loader){
        return;
    }
    loader.classList.remove('loader_show');
    document.querySelector('html').classList.remove('scroll-disabled');
}