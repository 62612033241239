import Toastify from "../plugins/toastify";

export default class Notify{
    static show(text, color = "#44559C"){
        return new Promise(reject => {
            Toastify({
                text,
                duration: 3000,
                newWindow: true,
                close: true,
                gravity: "top",
                position: "right",
                stopOnFocus: true,
                style: {
                    background: color,
                },
                onClick: reject
            }).showToast();
        })        
    }
    static info(text){
        return Notify.show(text);
    }
    static warning(text){
        return Notify.show(text, "#FF753A");
    }
    static error(text){
        return Notify.show(text, "#EA4545");
    }
    static success(text){
        return Notify.show(text, "#5cad72");
    }
}