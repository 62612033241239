export {initReCapcha, updateReCapcha}

let initRecapcha = false;
document.addEventListener('DOMContentLoaded', () => {
    document.body.addEventListener('change', onChangeInput);
});

function onChangeInput(e){
    if(!e.target.closest('form')){
        return;
    }
    initReCapcha();
    document.body.removeEventListener('change', onChangeInput);
}

function initReCapcha(){
    if(typeof(FILE_RECAPCHA_KEY) === 'undefined'){
        return;
    }

    let script = document.getElementById('google-recapcha');

    if(script){
        generateKeyRecapcha();
        return;
    }

    script = document.createElement('script');
    script.id = 'google-recapcha';
    script.src = 'https://www.google.com/recaptcha/api.js?render=' + FILE_RECAPCHA_KEY;
    script.setAttribute('async', '');

    script.onload = () => {
        script.onload = null;
        generateKeyRecapcha();
    }
    
    document.body.appendChild(script);
}

function generateKeyRecapcha(){
    if(typeof grecaptcha === 'undefined') {
        return;
    }

    grecaptcha.ready(function() {
        grecaptcha.execute(FILE_RECAPCHA_KEY, { 
            action:'validate_captcha' 
        }).then(token => {
            initRecapcha = true;
            document.querySelectorAll('input[name="recaptcha-response"]')
                .forEach(input => input.value = token)
        });
    });
}


function updateReCapcha(){    
    if(typeof('initReCapcha') === 'undefined'){
        return;
    }
    setTimeout(() => initReCapcha(), 1000);
}