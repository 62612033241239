const MAX_FILE_SIZE = 10;
import Notify from "../notify";

export default class Inputs{
    static init(){
        Inputs.update();
        document.querySelectorAll('.input, .textarea').forEach(x => {
            x.addEventListener('focusout', e => {
                const input = e.target.closest('input');
                if(input){
                    x.dataset.placeholder = input.value !== '' || input.type === 'date' || input.type === 'time' || input.type === 'datetime-local';
                    return;
                }
                const textarea = e.target.closest('textarea');
                if(textarea){
                    x.dataset.placeholder = textarea.value !== '';
                    return;
                }
            })
        })
        document.querySelectorAll('.file').forEach(x => {
            const input = x.querySelector('input');
            const value = x.querySelector('.file__value');
            input.addEventListener('change', e => {
                if (file.files && file.files[0]) {
    
                    const fileSize = file.files[0].size;
            
                    if (fileSize > MAX_FILE_SIZE * 1024 * 1024) {
                        Notify.error(`Розмір файлу завеликий, стисніть файл до потрібного розміру ${MAX_FILE_SIZE} Мб`);
                        return;
                    }
                    value.innerText = `${file.files[0].name} (${Math.round(fileSize / 1024 / 1024 * 1000) / 1000} Мб)`
                    x.classList.add('file_exist');
                }
            })
            x.addEventListener('click', e => {
                if(e.target.closest('.icon-close')){
                    input.type = "text";
                    input.type = "file";
                    input.value = '';
                    x.classList.remove('file_exist');
                    value.innerText = value.dataset.default;
                    return;
                }
                if(!x.classList.contains('file_exist')){
                    input.click();
                }                
            })
        })
    }
    
    static update(){
        document.querySelectorAll('.input, .textarea').forEach(x => {
            const input = x.querySelector('input');
            if(input){
                x.dataset.placeholder = input.value !== '' || input.type === 'date' || input.type === 'time' || input.type === 'datetime-local';
                return;
            }
            const textarea = x.querySelector('textarea');
            if(textarea){
                x.dataset.placeholder = textarea.value !== '';
                return;
            }
        })
    }
}