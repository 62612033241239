import Inputs from "./controls/input";
import Notify from "./notify";
import "../plugins/wow";
import { updateReCapcha } from "../plugins/recapcha";

window.onpageshow = function(event) {
    if (event.persisted) {
        window.location.reload(); 
    }
};

document.addEventListener('DOMContentLoaded', () => {
    Inputs.init();
    initHeader();
    initLinks();
    
    if(window.scrollY > 0){
        document.querySelectorAll('header.header .wow').forEach(x => x.classList.remove('wow'));
    }
    new WOW().init();
});

function initLinks(){
    const header = document.querySelector('header.header');
    document.querySelectorAll('a[href^="#"]:not([href^=mailto]):not([href^=tel]), a[href^="/#"]:not([href^=mailto]):not([href^=tel])').forEach(anchor => {
        anchor.addEventListener('click', e => {
            e.preventDefault();

            const href = e.currentTarget.getAttribute('href').replace('/', '');
            if(href === '#'){
                return;
            }
            const target =  document.querySelector(href);

            if(!target){
                location.href = e.currentTarget.getAttribute('href');
                return;
            }
            let offset = target.offsetTop; //высота от элемента к верху 

            if(target.dataset.scrollOffset){
                offset -= parseInt(target.dataset.scrollOffset)
            }
            offset -= header.getBoundingClientRect().height - 5;
            window.scrollTo({ 
                top: offset, 
                behavior: 'smooth'
            });
        });
    });
}

function initHeader(){
    const header = document.querySelector('.header');

    header.querySelector('.header__toggle').addEventListener('click', e => {
        header.classList.toggle('header_show');
        if(header.classList.contains('header_show')){
            document.querySelector('html').classList.add('scroll-disabled');
        }else{
            document.querySelector('html').classList.remove('scroll-disabled');
        }
    })

    header.querySelector('.header__menu').addEventListener('click', e => {
        header.classList.remove('header_show');
        document.querySelector('html').classList.remove('scroll-disabled');
    })

    header.dataset.scroll = window.scrollY > 0;

    window.addEventListener('scroll', e => {
        header.dataset.scroll = window.scrollY > 0;
        header.classList.remove('header_show');
        document.querySelector('html').classList.remove('scroll-disabled');
    });
}

function initCookies(){
    const cookiesForm = document.querySelector('.cookies-form');
    const cookiesAccept = cookiesForm.querySelector('.cookies-form__button_accept');
    const value = getCookie('allow-cookie');
    if(value === 'true'){
        return;
    }
    cookiesForm.classList.add('cookies-form_show');
    cookiesAccept.addEventListener('click', e => {
        setCookie('allow-cookie', true);
        cookiesForm.classList.remove('cookies-form_show');
    })
}

function getCookie(name, json=false) {
    if (!name) {
      return undefined;
    }
    let matches = document.cookie.match(new RegExp(
      "(?:^|; )" + name.replace(/([.$?*|{}()\[\]\\\/+^])/g, '\\$1') + "=([^;]*)"
    ));
    if (matches) {
      let res = decodeURIComponent(matches[1]);
      if (json) {
        try {
          return JSON.parse(res);
        }
        catch(e) {}
      }
      return res;
    }
  
    return undefined;
}

function setCookie(name, value, options = {path: '/'}) {
  if (!name) {
    return;
  }

  options = options || {};

  if (options.expires instanceof Date) {
    options.expires = options.expires.toUTCString();
  }

  if (value instanceof Object) {
    value = JSON.stringify(value);
  }
  let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);
  for (let optionKey in options) {
    updatedCookie += "; " + optionKey;
    let optionValue = options[optionKey];
    if (optionValue !== true) {
      updatedCookie += "=" + optionValue;
    }
  }
  document.cookie = updatedCookie;
}

function deleteCookie(name) {
  setCookie(name, null, {
    expires: new Date(),
    path: '/'
  })
}